import React, { useState } from 'react';
import './App.css';
import image1 from './images/1.png';
import image2 from './images/2.png';
import image3 from './images/3.jpg';
import image4 from './images/4.png';  
import image5 from './images/5.png'; 

function App() {
   // Create an array of image variable names
   const images = [image1, image2, image3, image4, image5];
     // Randomly select one image from the array
  const [selectedImage, setSelectedImage] = useState(images[Math.floor(Math.random() * images.length)]);

  //Function to update the selected image
  const updateBackgroundImage = () => {
    const newImage = images[Math.floor(Math.random() * images.length)];
    setSelectedImage(newImage);
  }

  // create a css style that adds a full screen background image
const backgroundStyle = {
  backgroundImage: `url(${selectedImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: '100vh',
  width: '100vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
}



  return (
    <div style={backgroundStyle} className="App">
      <header className={`App-header floating-div`}>
        <a
          className="App-link"
          href="https://www.linkedin.com/in/bvasko/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Bonnie Dipasquale, Frontend Developer<br/>
          -- Coming Soon --
        </a>
        <button onClick={updateBackgroundImage} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px' }}>
          Change Background
        </button>
      </header>
    </div>
  );
}

export default App;
